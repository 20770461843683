<template>
  <v-dialog
      v-model="showCustomerPopup"
      max-width="780"
      @input="$emit('close')"
      persistent
  >
    <v-card class="px-0 pt-0">
      <div class="px-4 pt-4 pb-0 d-flex justify-space-between align-center border-bottom">
        <v-row class="px-0 py-0 m-0" >
          <v-col cols="8">
            <h3 class="font-semibold text-blue pb-2">
                Customer Details
            </h3>
          </v-col>
          <v-col cols="4">
            <div v-if="promotions.length > 0" class="promotion-dd">
                <v-autocomplete
                  v-if="customers && customers[0] && customers[0].card_number == null"
                  :items="[ { name: 'Promotion', promotion_code: null }, ...promotions,]"
                  item-text="name"
                  label=""
                  item-value="promotion_code"
                  v-model="bookingData.promotion_code"
                  outlined
                  @change="verifyBenefit('promotion')"
                  dense
                  hide-details="auto"
                  class="q-autocomplete shadow-0"
                >
                </v-autocomplete>
              </div>
          </v-col>
        </v-row>
      

        <div class="pointer" @click="$emit('close')">
          <ModalCloseIcon/>
        </div>
      </div>
      <v-form ref="cform">
        <v-row class="px-5 py-0 m-0" v-for="(bookingForm, index) in customers" :key="index">
          <v-col md="12" sm="6" class="pb-2">
            <div class="font-bold blue-text relative border-bottom">
              <div class="customer-heading">{{ index == 0?'Primary': 'Additional' }} Customer  {{ index > 0?index:'' }}</div>
              <v-tooltip bottom v-if="index > 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      color="red"
                      fab
                      x-small
                      absolute
                      top
                      right
                      dark
                      @click="deleteCustomer(index)"
                      v-if="index > 0"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                Delete
              </v-tooltip>
            
            </div>
          </v-col>
          <v-col md="12" sm="12" class="m-0 py-0" >
        
              <div class="top-member-radio-div" >
                <div class="customer-list" v-if="getOrderCustomerList.length">
                  <label for="">
                    Customers
                  </label>
                    <v-autocomplete
                      :items="[...getOrderCustomerList]"
                      item-text="name"
                      label=""
                      placeholder="Select Customer"
                      item-value="customer_id"
                      v-model="selectedCustomers[index]"
                      return-object
                      outlined
                      @change="(data) => changeSelectedCustomer(data,index)"
                      dense
                      hide-details="auto"
                      class="q-autocomplete shadow-0"
                    >
                    </v-autocomplete>
                </div>
                <div class="member-radio" v-if="bookingType != 'membership' && bookingType != 'retail' && index == 0">
                  <v-radio-group
                    v-model="bookingForm.customer_type"
                    class-name="custom-radio-group shadow-0 mt-1"
                    row
                    @change="customerTypeChange"
                    mandatory
                    :dense="true"
                    hide-details="auto"
                    outlined
                  >
                    <v-radio label="Normal" class="custom-radio" color="cyan" value="normal"></v-radio>
                    <v-radio label="Member" class="custom-radio" color="cyan" value="member"></v-radio>
                  </v-radio-group>
                </div>
                <div class="member-card-div" v-if="bookingForm.customer_type == 'member' && index == 0">
                  <label for="">
                    Card Number*
                  </label>
                  <v-member-search
                      v-model="bookingForm.member"
                      @clear="clearBenefit"
                      :selected="bookingForm.card_number"
                      @updateCustomer="(data) => setMemberData(data,index)"
                      class-name="q-text-field shadow-0"
                      :dense="true"
                      :showLabel="false"
                      hide-details="auto"
                      outlined
                      :venueId="getCurrentVenueId"
                    >
                  </v-member-search>
                </div>
            </div>
            
          </v-col>
          <v-col md="6" sm="12">
            <label for="">
              Mobile No*
            </label>
            <v-mobile-search
                v-model="bookingForm.search"
                :dense="true"
                :selected="bookingForm.mobile"
                ref="mobile"
                background-color=""
                class-name="q-text-field shadow-0"
                hide-details="auto"
                outlined
                readonly
                @updateCustomer="setCustomerData($event,index,true)"
                :showLabel="false"
                :venueId="getCurrentVenueId"
            ></v-mobile-search>
          </v-col>

          <v-col md="6" sm="12">
            <label for="">
              Name*
            </label>
            <v-name-search
                v-model="bookingForm.nameSearch"
                :dense="true"
                :email="bookingForm.email"
                :mobile="bookingForm.mobile"
                :selected="bookingForm.name"
                @updateCustomer="setCustomerData($event,index,true)"
                background-color=""
                class-name="q-text-field shadow-0"
                hide-details="auto"
                outlined
                readonly
                label=""
                :venueId="getCurrentVenueId"
            >
            </v-name-search>
          </v-col>

          <v-col md="6" sm="12" >
            <label for="">
              Email{{ field.email.is_required ? '*' : '' }}
            </label>
            <v-text-field
                v-model="bookingForm.email"
                :dense="true"
                :placeholder="`Email${field.email.is_required ? '*' : ''}`"
                :readonly="bookingForm.customer_id != null"
                class="q-text-field shadow-0"
                hide-details="auto"
                outlined
                required
                :rules="[
                  (v) => !!v || 'E-mail is required',
                  (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                ]"
            ></v-text-field>
          </v-col>

          <v-col v-if="field.gender.is_visible" md="6" sm="12">
            <label for="">
              Gender{{ field.gender.is_required ? '*' : '' }}
            </label>
            <v-radio-group
                v-model="bookingForm.gender"
                class="mt-0 gender-radio"
                :rules="genderRule"
                hide-details="auto"
                row
            >
              <v-radio
                  label="Male"
                  value="Male"
              ></v-radio>
              <v-radio
                  label="Female"
                  value="Female"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col v-if="field.dob.is_visible && customerAgeRange" md="6" sm="12">
            <label for="">
              Age Group{{ field.dob.is_required ? '*' : '' }}
            </label>
            <v-select
                v-if="customerAgeRange"
                v-model="bookingForm.age_group"
                :dense="true"
                :items="[]"
                :menu-props="{ bottom: true, offsetY: true }"
                class-name="q-text-field shadow-0"
                hide-details="auto"
                item-text="name"
                item-value="id"
                outlined
            ></v-select>
          </v-col>
          <v-col v-if="field.dob.is_visible && !customerAgeRange" md="6" sm="12">
            <label for="">
              Date of Birth{{ field.dob.is_required ? '*' : '' }}
            </label>
            <date-of-birth
                v-model="bookingForm.dob"
                :rules="dobRule"
                :dense="true"
                class-name="q-text-field shadow-0"
                hide-details="auto"
                label=""
            >
            </date-of-birth>
          </v-col>
          <v-col v-if="field.nationality.is_visible" md="6" sm="12">
            <label for="">
              Nationality{{ field.nationality.is_required ? '*' : '' }}
            </label>
            <v-autocomplete
                v-model="bookingForm.country_id"
                :hint="`Nationality${field.nationality.is_required ? '*' : ''}`"
                :items="countries"
                background-color="#fff"
                dense
                :rules="nationalityRule"
                hide-details="auto"
                item-text="name"
                item-value="id"
                outlined
                class="q-autocomplete shadow-0"
            ></v-autocomplete>
          </v-col>
          <v-col v-if="field.idProof.is_visible" md="6" sm="12">
            <label for="">
              ID Type{{ field.idProof.is_required ? '*' : '' }}
            </label>
            <v-select
                v-model="bookingForm.id_proof_type_id"
                :rules="idTypeRule"
                :dense="true"
                :menu-props="{ bottom: true, offsetY: true }"
                background-color="#fff"
                class="q-autocomplete shadow-0"
                hide-details="auto"
                outlined
                item-value="id"
                item-text="name"
                :items="idProofTypes"
                @change="changeIdProofTypeId($event, index)"
            ></v-select>
          </v-col>
          <v-col v-if="field.idProof.is_visible" md="6" sm="12">
            <v-row no-gutters>
              <v-col md="7">
                <label for="">
                  ID Number{{ field.idProof.is_required ? '*' : '' }}
                </label>
                <v-text-field
                    v-model="bookingForm.id_proof_number"
                    :hint="`ID Number${field.idProof.is_required ? '*' : ''}`"
                    :dense="true"
                    :rules="idTypeRule"
                    background-color="#fff"
                    class="q-text-field shadow-0"
                    hide-details="auto"
                    outlined
                ></v-text-field>
              </v-col>
              <v-col md="5">
                <label for="">
                  {{
                    (bookingForm.id_proof_path ? 'Download' : 'ID Proof') + (field.idProof.is_required ? '*' : '')
                  }}
                </label>
                <v-file-input
                    v-model="bookingForm.id_proof"
                    :rules="idProofRule(bookingForm)"
                    :dense="true"
                    background-color="#fff"
                    class="q-text-field shadow-0"
                    hide-details="auto"
                    outlined
                    prepend-icon=""
                >
                  <template v-slot:prepend-inner>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                            v-if="bookingForm.id_proof_path"
                            color="cyan"
                            @click="openFile(bookingForm.id_proof_path)"
                            v-on="on"
                        >
                          mdi-download-box
                        </v-icon>
                        <v-icon v-else v-on="on"> mdi-card-account-details</v-icon>
                      </template>
                      <span v-if="bookingForm.id_proof_path">
                        Download uploaded file
                      </span>
                      <span v-else>Upload ID Proof</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:selection="{ index, text }">
                    <v-chip
                        v-if="index == 0"
                        color="cyan accent-4"
                        dark
                        label
                        small
                    >
                    <span class="text-truncate" style="width: 38px">
                      {{ text }}
                    </span>
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <!-- <div style="margin-top: -110px"></div> -->
          </v-col>
          <v-col v-if="field.tag.is_visible" md="6" sm="12">
            <label for="">
              Tags{{ field.tag.is_required ? '*' : '' }}
            </label>
            <v-select
                v-model="bookingForm.customer_tag"
                :rules="tagRule"
                :items="tags"
                :menu-props="{ bottom: true, offsetY: true }"
                :placeholder="`Tags${field.tag.is_required ? '*' : ''}`"
                background-color="#fff"
                dense
                hide-details="auto"
                item-text="name"
                item-value="id"
                multiple
                outlined
                return-object
                class="q-autocomplete shadow-0"
            ></v-select>
          </v-col>
          <v-col v-if="field.image.is_visible" md="6" sm="12">
            <v-row no-gutters>
              <v-col md="12">
                <label for="">
                  {{
                    (bookingForm.profile_image ? 'Change image' : 'Upload Image') + (field.image.is_required ? '*' : '')
                  }}
                </label>
                <v-file-input
                    v-model="bookingForm.profile_image"
                    :rules="imageRule(bookingForm)"
                    background-color="#fff"
                    dense
                    hide-details="auto"
                    outlined
                    prepend-icon=""
                    show-size
                    class="q-text-field shadow-0"
                >
                  <template v-slot:prepend-inner>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                            color="cyan"
                            v-if="bookingForm.image_path"
                            @click="openFile(bookingForm.image_path)"
                            v-on="on"
                        >
                          mdi-download-box
                        </v-icon>
                        <v-icon v-else v-on="on">mdi-image</v-icon>
                      </template>
                      <span v-if="bookingForm.image_path"> Download image</span>
                      <span v-else>Upload Image</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:selection="{ index, text }">
                    <v-chip
                        v-if="index == 0"
                        color="cyan accent-4"
                        dark
                        label
                        small
                    >
                      <span style="width: 120px" class="text-truncate">{{ text }}</span>
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="mt-2 "   v-if="documentFields.length > 0 && index === 0">
              <div class="d-flex align-center justify-space-between">
                <div class="font-semibold text-base text-blue mb-2">Documents{{field.image.is_required ? '*' : ''}}</div>
              </div>
              <v-row>
                <v-col
                    cols="12"
                    v-for="(documentField, dIndex) in documentFields"
                    :key="`d_${dIndex}`"
                >
                  <v-file-input
                      :label="documentField.name"
                      v-model="documentField.file"
                      prepend-inner-icon="mdi-paperclip"
                      prepend-icon
                      outlined
                      background-color="#fff"
                      :rules="documentUploadRule(documentField)"
                      dense
                      hide-details="auto"
                      class="q-text-field shadow-0"
                  >
                    <template v-slot:prepend-inner>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                              color="cyan"
                              v-if=" documentField.document_id && documentField.file_path"
                              @click="openFile(documentField.file_path)"
                              v-on="on"
                          >
                            mdi-download-box
                          </v-icon>
                          <v-icon v-else v-on="on"> mdi-paperclip</v-icon>
                        </template>
                        <span v-if="documentField.document_id && documentField.file_path">Download uploaded file</span>
                        <span v-else>Upload Document</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:label>
                      <span v-if="!documentField.isEdit">
                        {{ documentField.name }} {{ documentField.is_required?'*':''}}
                      </span>
                      <span v-if="documentField.isEdit == true && documentField.file == null && documentField.original_file_name" class="font-weight-bold">
                        <span style="width: 70%; display: inline-block" class="text-truncate">{{ documentField.original_file_name }}</span>
                        <span style="width: 20%; display: inline-block" class="text-truncate">.
                          {{ documentField.original_file_name.split(".")[documentField.original_file_name.split(".").length - 1] }}
                        </span>
                      </span>
                    </template>
                    <template v-slot:append v-if="documentField.uploaded_file_path">
                      <v-btn
                          @click="openFile(documentField.uploaded_file_path)"
                          class="text-truncate font-12"
                          depressed
                          color="primary"
                          text
                          style="transform: translate(0, -22%)"
                      >
                        Download {{ documentField.name }}
                      </v-btn>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
          </v-col>
        </v-row>
        <v-row class="px-5 py-3 m-0" v-if="isShowCustomerBtn">
          <v-col md="12">
            <v-btn class="white--text teal-color" elevation="0" height="40" @click="addNewCustomer">
              + Add Customer
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
        <div class="mt-8 d-flex  align-center bg-cyan pa-4">
          <v-row class="d-flex align-center px-4">
            <v-col cols="9" class="d-flex align-center">
              <card-reader-button
                  className=" mt-5 text-blue text-capitalize light-blue-color"
                  docType="chip"
                  label="Samsotech Reader"
                  @data="
                    (data) => {
                      setCardData(data);
                    }
                  "
              ></card-reader-button>
              <card-data-button
                  className="mt-5 ml-4 text-blue text-capitalize light-blue-color"
                  label="HID Omnikey"
                  @data="
                    (data) => {
                      setCardData(data);
                    }
                  "
              ></card-data-button>
            </v-col>
            
            <v-spacer></v-spacer>
            <v-col cols="3">
                <v-btn
                  class="white--text blue-color mt-5 ml-4"
                  height="40"
                  text
                  @click="saveCustomer"
                >
                Add Customer
                </v-btn>
           </v-col>
           </v-row>
        </div>
    </v-card>
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
  ></confirm-model>
  </v-dialog>

</template>
<script>
import ModalCloseIcon from "@/assets/images/misc/modal-close.svg";
import VMemberSearch from "@/components/Customer/VMemberSearch";
import bookingFields from "@/mixins/ShopBookingFieldValidation";
export default {
  components: {ModalCloseIcon,VMemberSearch},
  props: {
    bookingType:null,
    promotions:{ type: Array, default: () => []},
    index:{type: Number, default:0},
    showCustomerPopup: {type: Boolean, default:false},
    venue_service_id: { type: Number,default: 0 },
    configuration: { // fields configuration
      type: Object,
      default: () => ({
        email: {is_visible: true, is_required: false},
        gender: {is_visible: true, is_required: false},
        dob: {is_visible: true, is_required: false},
        nationality: {is_visible: true, is_required: false},
        idProof: {is_visible: true, is_required: false},
        tag: {is_visible: true, is_required: false},
        image: {is_visible: true, is_required: false},
      })
    },
    customerAgeRange: {
      type: Boolean,
      default: false
    },
    maxCustomers:{ type: Number, default: 1},
    bookingData:{ type: Object,  default: () => null }
  },
  mixins: [bookingFields],
  mounted(){
    if(this.bookingData && this.bookingData.products){
      this.products = [...this.bookingData.products];
    }

    if (this.$store.getters.getIdProofTypes.status == false) {
      this.$store.dispatch("loadIDProofTypes");
    }
    if (this.$store.getters.getTags.status == false) {
      this.$store.dispatch("loadTags");
    }
    this.setFieldConfigurations();
    this.setDocumentFields();

  },
  computed: {
    // customerPopup: {
    //   get() {
    //     return this.showCustomerPopup
    //   },
    //   set(value) {
    //     this.$emit('close', value)
    //   }
    // },
    countries() {
      return this.$store.getters.getCountries.data;
    },
    idProofTypes() {
      return this.$store.getters.getIdProofTypes.data;
    },
    tags() {
      return this.$store.getters.getTags.data;
    },
    isShowCustomerBtn(){
      return (this.maxCustomers - this.customers.length) > 0;
    },
    getOrderCustomerList(){
      let cardITems = this.$store.getters.getCartItems;
      let cust = [];
      if(cardITems){
        cardITems.forEach( (order) => {
          if(order.customers){
            order.customers.forEach( (customer) => {
              cust.push(customer);
            })
          } 
        });
      }
      return cust;
    },
    getCurrentVenueId(){
      if(this.bookingData && this.bookingData.venue_id){
        return this.bookingData.venue_id;
      }
      return this.$store.getters.svid;
    },
  },
  data() {
    return {
      customers: [{
        search: {},
        nameSearch: {},
        mobile: '',
        name: '',
        email: '',
        id_proof_path: '',
        gender: '',
        customer_type:'normal',
        customer_tag:null,
        promotion_code: null,
        documents:[],
      
      }],
      products: [],
      selectedCustomers:[], 
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      genders:[
        {
          value:"male",
          text:"Male"
        },
        {
          value:"female",
          text:"Female"
        }
      ]
    }
  },
  methods: {
    saveCustomer() {
      if (!this.$refs.cform.validate()) {
        this.showError("Please fill all required fields to continue");
        return;
      }
      // this.customerPopup = false;
      if(this.bookingType && this.bookingType == "facility"){

        this.$emit("setCustomers",{
          index:this.index,
          customers:this.customers
        });
      }else{
        let documents = [];
        if(this.documentFields.length > 0){
          this.documentFields.forEach((documentField) => {
            if (documentField.file || documentField.isEdit) {
              documents.push(documentField);
            }
          });
        }
        if(documents.length){
          documents.forEach( (element) => {
            if (element.file) {
              this.customers[0]['documents'].push({name:element.name,id:element.id,file:element.file});
            }
          });
        }
        this.$emit("setCustomers",this.customers);
      }
    },
    capitalizeFirstLetter(str) {
      if (!str) return '';
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    },
    setCardData(data) {
      this.setCustomerData(data,0);
    },
    setCustomerData(data, index = 0, isMemberCheck=false) {
      if (data.isEmiratesIdCheck) {
        this.isEmiratesIdCheck = true;
      }
      if (data.mobile && data.first_name && data.customer_id) {
        this.isEmiratesIdCheck = false;
        if(index == 0){
          let cn = null;
          if(isMemberCheck){
            cn = data.card_number;
          }
          this.searchMember(
            data.mobile,
            data.customer_id,
            data.first_name,
            data.last_name,
            index,
            cn
          );
        }
      } else {
        if(index == 0){
          this.clearCardAndBenefits();
        }
      }
      if (!data.name && data.first_name) {
        this.$set(this.customers[index], "name", data.first_name);
      }
      if (
        this.customers[index].customer_id &&
        !data.customer_id &&
        this.customers[index].name != data.name &&
        this.customers[index].mobile != data.mobile
      ) {
        this.$set(this.customers[index], "mobile", null);
        this.customers[index].search = null;
        this.customers[index].nameSearch = null;
        this.$set(this.customers[index], "email", null);
        this.$set(this.customers[index], "gender", null);
        this.$set(this.customers[index], "name", null);
        this.$set(this.customers[index], "customer_id", null);
        this.$set(this.customers[index], "first_name", null);
        this.$set(this.customers[index], "image_path", null);
        this.$set(this.customers[index], "dob", null);
        this.$set(this.customers[index], "age_group", null);
        this.$set(this.customers[index], "country_id", null);
        this.$set(this.customers[index], "last_name", null);
        this.$set(this.customers[index], "opt_marketing", false);
        this.$set(this.customers[index], "id_proof_type_id", null);
        this.$set(this.customers[index], "id_proof_number", null);
        this.$set(this.customers[index], "id_proof_path", null);
        this.$set(this.customers[index], "customer_tag", null);
        this.$forceUpdate();
      }
      if (data.mobile) this.$set(this.customers[index], "mobile", data.mobile);
      if (data.email) this.$set(this.customers[index], "email", data.email);
      if (data.country_id) {
        this.$set(this.customers[index], "country_id", data.country_id);
      } else {
        if (!this.isEmiratesIdCheck && data.customer_id) {
          this.$set(this.customers[index], "country_id", null);
        }
      }
      if (data.gender) {
        this.$set(this.customers[index], "gender", this.capitalizeFirstLetter(data.gender));
      } else {
        if (!this.isEmiratesIdCheck && data.customer_id) {
          this.$set(this.customers[index], "gender", null);
        }
      }
      if (data.dob) {
        this.$set(this.customers[index], "dob", data.dob);
      } else {
        if (!this.isEmiratesIdCheck && data.customer_id) {
          this.$set(this.customers[index], "dob", null);
        }
      }
      if (data.age_group) {
        this.$set(this.customers[index], "age_group", data.age_group);
      }
      else {
        if (!this.isEmiratesIdCheck && data.customer_id) {
          this.$set(this.customers[index], "age_group", null);
        }
      }
      if (data.name) {
        data.name = data.name.replace(/\s\s+/g, " ");
        data.name = data.name.trim();
        this.$set(this.customers[index], "name", data.name);
      }
      if (data.last_name) {
        data.last_name = data.last_name.replace(/\s\s+/g, " ");
        data.last_name = data.last_name.trim();
        this.$set(this.customers[index], "last_name", data.last_name);
      } else {
        this.$set(this.customers[index], "last_name", null);
      }
      if (data.first_name) {
        data.first_name = data.first_name.replace(/\s\s+/g, " ");
        data.first_name = data.first_name.trim();
        this.$set(this.customers[index], "first_name", data.first_name);
      }
      if (data.customer_id)
        this.$set(this.customers[index], "customer_id", data.customer_id);
      if (data.image_path) {
        this.$set(this.customers[index], "image_path", data.image_path);
      } else {
        this.$set(this.customers[index], "image_path", null);
      }
      if (data.id_proof_type_id) {
        this.$set(
          this.customers[index],
          "id_proof_type_id",
          data.id_proof_type_id
        );
      } else {
        if (!this.isEmiratesIdCheck && data.customer_id) {
          this.$set(this.customers[index], "id_proof_type_id", null);
        }
      }
      if (data.id_proof_number) {
        this.$set(this.customers[index], "id_proof_number", data.id_proof_number);
      } else {
        if (!this.isEmiratesIdCheck && data.customer_id) {
          this.$set(this.customers[index], "id_proof_number", null);
        }
      }
      if (data.id_proof_path) {
        this.$set(this.customers[index], "id_proof_path", data.id_proof_path);
      } else {
        if (!this.isEmiratesIdCheck && data.customer_id) {
          this.$set(this.customers[index], "id_proof_path", null);
        }
      }
      if (data.customer_tag) {
        this.$set(this.customers[index], "customer_tag", data.customer_tag);
      } else {
        this.$set(this.customers[index], "customer_tag", null);
      }

      if (data.id_proof) {
        this.$set(this.customers[index], "id_proof", data.id_proof);
      } else {
        if (!this.isEmiratesIdCheck && data.customer_id) {
          this.$set(this.customers[index], "id_proof", null);
        }
      }
      if (data.opt_marketing) {
        if (data.opt_marketing == 1) {
          this.$set(this.customers[index], "opt_marketing", true);
        } else {
          this.$set(this.customers[index], "opt_marketing", false);
        }
      }
      if (data.customer_documents) {
        this.customers[index].customer_documents = data.customer_documents;
        if (
          data.customer_documents[index] &&
          data.customer_documents[index].id_proof_type_id
        ) {
          this.$set(
            this.customers[index],
            "id_proof_type_id",
            data.customer_documents[index].id_proof_type_id
          );
        }
        if (
          data.customer_documents[index] &&
          data.customer_documents[index].id_proof_number
        ) {
          this.$set(
            this.customers[index],
            "id_proof_number",
            data.customer_documents[index].id_proof_number
          );
        }
        if (
          data.customer_documents[index] &&
          data.customer_documents[index].id_proof_path
        ) {
          this.$set(
            this.customers[index],
            "id_proof_path",
            data.customer_documents[index].id_proof_path
          );
        }
      } else {
        if (data.customer_id) {
          this.customers[index].customer_documents = [];
        }
      }
      
      this.$refs.cform.resetValidation();
      this.$forceUpdate();
    },
    changeIdProofTypeId(data, index = 0) {
      if (index !== null) {
        if (
         data.customer_documents &&
         data.customer_documents.length
        ) {
          let objType = data.customer_documents.find(
            (x) => {
              return (
                x.id_proof_type_id ===
               data.id_proof_type_id
              );
            }
          );
          if (typeof objType !== "undefined" && objType.id_proof_type_id) {
           data.id_proof_number =
              objType.id_proof_number;
           data.id_proof_path = objType.id_proof_path;
          } else {
           data.id_proof_number = null;
           data.id_proof_path = null;
          }
        } else {
          console.log("document length 0");
        }
      }
    },
    addNewCustomer() {
      this.customers.push({
        search: {},
        nameSearch: {},
        mobile: '',
        name: '',
        email: '',
        id_proof_path: '',
        gender: '',
        customer_type:'normal',
        customer_tag:null,
      });
    },
    deleteCustomer(index) {
      this.confirmModel = {
        id: index,
        title: "Do you want to remove?",
        description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "customer",
      };
    },
    confirmActions(data) {
      if (data.type === "customer") {
        this.customers.splice(data.id, 1);
      }
      this.confirmModel.id = null;
    },
    setMemberData(data, index = 0) {
      if(index != 0){
        return;
      }
      this.setCustomerData(data, index,true);
      if (index === 0) {
        this.$set(this.customers[index], "card_number", data.card_number);
        this.$set(this.customers[index], "membership_id", data.membership_id);
        this.verifyBenefit("membership", index);
      } 
    },
    searchMember(mobile, id, first_name, last_name, index = 0,card_number=null) {
      if(index != 0){
        return;
      }
      this.isSearchLoading = true;
      let query = "";
      if(card_number){
        query = `field=display_number&search=${card_number}`;
      }else{
        query = `field=id&search=${id}`;
      }
      if(this.getCurrentVenueId){
        query += "&venueId="+this.getCurrentVenueId; 
      }

      this.$http.get(`venues/memberships/members/filters?${query}`).then((response) => {
        if (response.status == 200) {
          let data = response.data.data;
          if (data.length > 0) {
            if (data[0].card_number) {
              if (index === 0) {
                this.customers[0].customer_type = "member";
                this.$set(this.customers[0],"card_number",data[0].card_number);
                this.$set(this.customers[0],"membership_id",data[0].membership_id);
                this.$forceUpdate();
                if (this.bookingData && this.bookingData.products.length > 0) {
                  this.verifyBenefit("membership");
                }
              } else {
                this.$set(this.customers[index], "customer_type", "member");
                this.$set(this.customers[index], "card_number", data[0].card_number);
                this.$set(this.customers[index], "membership_id", data[0].membership_id);
                this.$forceUpdate();
                if ( this.bookingData && this.bookingData.products.length) {
                  this.verifyBenefit("membership", index);
                }
              }
            } else {
              if (index === 0) {
                if (this.customers[0].customer_type == "member") {
                  this.customers[0].customer_type = "normal";
                  this.$set(this.customers[0], "customer_type", "normal");
                  this.$set(this.customers[0], "membership_id", null);
                }
              } else {
                if ( this.customers[index].customer_type == "member") {
                  this.customers[index].customer_type = "normal";
                  this.customers[index].membership_id = null;
                }
              }
              this.clearCardAndBenefits(index);
            }
          } else {
            if (index === 0) {
              if (this.customers[0].customer_type == "member") {
                this.customers[0].customer_type = "normal";
                this.$set(this.customers[0], "customer_type", "normal");
                this.$set(this.customers[0], "membership_id", null);
              }
            } else {
              if (this.customers[index].customer_type == "member") {
                this.customers[index].customer_type = "normal";
                this.customers[index].membership_id = null;
              }
            }
            this.clearCardAndBenefits(index);
          }
          this.$forceUpdate();
        } else {
          this.clearCardAndBenefits(index);
        }
      })
      .catch((error) => {
        this.errorChecker(error);
        this.clearCardAndBenefits(index);
      });
    },
    verifyBenefit(type, index = 0) {
      this.clearBenefit(index);
      if (index === 0 && this.bookingData.products.length == 0) {
        this.showError("Please add at least one product");
        return;
      }
      // if (index !== null && this.customers[index] && this.customers[index].products && this.customers[index].products.length == 0) {
      //   this.showError("Please add atleast one product");
      //   return;
      // }
      if(index != 0){
        return;
      }
      let data = {
        products: [],
      };
      this.showLoader();
      if (type == "promotion" && index === 0) {
        data.promotion_code =  this.bookingData.promotion_code;
        if (data.promotion_code === null) {
          this.hideLoader();
          this.clearBenefit(index);
          return;
        }
      } else if(type == "membership" && index === 0){
          data.card_number = this.customers[0].card_number;
      }
      if (this.customers[0].mobile && index === 0 ) {
        data.mobile = this.customers[0].mobile;
      }
     
      if (index === 0) {
        data.products = [...this.products];
        // if (this.bookingData.discount) {
        //    data.products = [];
        //   this.bookingData.products.forEach((product,ind) => {
        //     let pdata = product;
        //     if (product.discount) {
        //       pdata.price = product.discount.actual_price;
        //      delete pdata.discount;
        //     } else if (product.product_price) {
        //       pdata.price = product.product_price;
        //     }
        //     data.products.push(pdata);
        //   });
        // } else {
        //   data.products = this.bookingData.products;
        //   data.products.forEach((element) => {
        //     if (element.product_price) {
        //       element.price = element.product_price;
        //     } else if(element.product_type_id == 6){
        //       element.price = element.price / element.quantity;
        //     }
        //   });
        // }
        console.log("data products");
        console.log(data.products);
        data.products.forEach((element) => {
          if (element.product_price) {
              element.price = element.product_price;
          } else if(element.product_type_id == 6){
              element.price = element.price / element.quantity;
            }
        });
      }
      if(this.getCurrentVenueId){
        data.venueId = this.getCurrentVenueId;
      }
      let url = `venues/benefits/verify`;
      this.$http
        .post(url, data)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const beniData = response.data.data;
            this.bookingData.discount = beniData.discount;
            this.bookingData.price = beniData.price;
            this.bookingData.products = beniData.products;
            this.bookingData.total_price = beniData.total;
            if(data.promotion_code && type == "promotion"){
              this.bookingData.promotion_code = data.promotion_code;
            }
            if(beniData.discount){
              this.showSuccess("Discount Applied");
            }
            this.hideLoader();
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    clearBenefit(index = 0) {
      if (this.bookingData && this.bookingData.discount && index === 0) {
        if (this.customers[index].customer_type != "member") {
          this.customers[index].card_number = null;
        }
        if(this.customers[index].promotion_code){
          this.customers[index].promotion_code = null;
        }
        this.bookingData.price = this.bookingData.discount.actual_price;
        this.bookingData.total_price = this.bookingData.discount.actual_total;

        this.bookingData.products = [...this.products];
        this.bookingData.products.forEach((product, ind) => {
         
          // if (product.discount) {
          //   this.bookingData.products[ind].price = product.discount.actual_price;
          //   this.bookingData.products[ind].total_amount = product.discount.actual_total;
          //   this.bookingData.products[ind].total_price = product.discount.actual_total;
          // }
          this.bookingData.products[ind].discount = null;
        });
        setTimeout(() => {
          if(this.bookingData.promotion_code){
            this.bookingData.promotion_code = null;
          }
          this.bookingData.discount = null;
          this.$forceUpdate();
        });
      }
    },
    customerTypeChange(e, index = 0) {
      if (index === 0) {
        if (this.customers[index].customer_type == "normal" && this.customers[index].card_number != null) {
          this.$set(this.customers[index], "card_number", null);
          this.clearBenefit();
        }
      }
    },
    clearCardAndBenefits(attCustomerIndex = 0) {
      if (attCustomerIndex === 0) {
        this.customers[0].member = null;
        this.customers[0].customer_type = 'normal';
        this.$set(this.customers[0], "card_number", null);
        if (this.bookingData && this.bookingData.promotion_code == null) {
          this.clearBenefit();
        }
      } else {
        if (this.customers[attCustomerIndex]) {
          this.customers[attCustomerIndex].customer_type = this.customers[attCustomerIndex].customer_type? this.customers[attCustomerIndex].customer_type: null;
          this.$set(this.customers[attCustomerIndex],"card_number",null);
          if (this.customers[attCustomerIndex].promotion_code == null) {
            this.clearBenefit(attCustomerIndex);
          }
        }
      }
      this.$forceUpdate();
    },
    changeSelectedCustomer(data,index){
      this.setCustomerData(data,index,false);
    },
    documentUploadRule(field) {
      const rules = [];
      if (field.is_required && !field.isEdit) {
        const rule = (v) => !!v || "Document is required";
        rules.push(rule);
      }
      return rules;
    },
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .gender-radio {
    padding-top: 0 !important;

    .v-radio {
      display: flex;
      justify-content: center;
      border: 1px solid #A6A6A6;
      border-radius: 0.25rem;
      flex-grow: 1;

      label {
        justify-content: center;
      }

      &.v-item--active {
        border: 1px solid #112A46;
        background-color: rgba(17, 42, 70, 0.1);
        color: #112A46;

        label {
          font-weight: 600 !important;
        }
      }
      &:last-child{
        margin-right: 0 !important;
      }
    }

    .v-label {
      min-width: 7.8rem;
      padding: 0.65rem;
    }

    .v-input--selection-controls__input {
      display: none !important;
    }

  }
}
p.customer-title {
    margin-bottom: 0;
    border-bottom: 1px solid #ccc;
    padding: 10px 5px;
    background: #fbfbfb;
    font-size: 14px;
    font-weight: 600;
    position: relative;
}
p.customer-title button{
  top: 5px;
}
.top-member-radio-div {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
    /* flex-flow: row nowrap; */
}
.top-member-radio-div .member-radio {
    margin-top: 3px;
    width: 35%;
}

.top-member-radio-div .customer-list {
    width: 30%;
}

.top-member-radio-div .v-radio.custom-radio.theme--light.v-item--active {
    margin-right: 0px;
}

.top-member-radio-div .member-card-div {
    width: 32%;
}
</style>
const shopBookingFields = {
    data() {
        return {
            field: {
                mobile: {
                    slug: process.env.VUE_APP_MOBILE_FIELD,
                    is_visible: true,
                    is_required: true,
                },
                name: {
                    slug: process.env.VUE_APP_NAME_FIELD,
                    is_visible: true,
                    is_required: true,
                },
                email: {
                    slug: process.env.VUE_APP_EMAIL_FIELD,
                    is_visible: true,
                    is_required: true,
                },

                gender: {
                    slug: process.env.VUE_APP_GENDER_FIELD,
                    is_visible: true,
                    is_required: false,
                },
                dob: {
                    slug: process.env.VUE_APP_DOB_FIELD,
                    is_visible: true,
                    is_required: false,
                },
                nationality: {
                    slug: process.env.VUE_APP_NATIONALITY_FIELD,
                    is_visible: true,
                    is_required: false,
                },
                idProof: {
                    slug: process.env.VUE_APP_ID_FIELD,
                    is_visible: true,
                    is_required: false,
                },
                image: {
                    slug: process.env.VUE_APP_IMAGE_FIELD,
                    is_visible: true,
                    is_required: false,
                },
                terms_conditions: {
                    slug: process.env.VUE_APP_TNC_FIELD,
                    is_visible: true,
                    is_required: false,
                },
                tag: {
                    slug: process.env.VUE_APP_TAG_FIELD,
                    is_visible: true,
                    is_required: false,
                },
            },
            documentFields: [],
        };
    },
    computed: {
        nameRule() {
            const rules = [];
            if (this.field.name.is_required) {
                const rule = (v) => !!v || "Name is required";
                rules.push(rule);
            }
            return rules;
        },
        emailRule() {
            const rules = [];
            if (this.field.email.is_required) {
                const rule = (v) => !!v || "Email is required";
                rules.push(rule);
            }
            const rule = (v) => !v || /.+@.+\..+/.test(v) || "E-mail must be valid";
            rules.push(rule);
            return rules;
        },
        tagRule() {
            const rules = [];
            if (this.field.tag.is_required) {
                const rule = (v) => !!v || "Tag is required";
                rules.push(rule);
            }
            return rules;
        },
        genderRule() {
            const rules = [];
            if (this.field.gender.is_required) {
                const rule = (v) => !!v || "Gender is required";
                rules.push(rule);
            }
            return rules;
        },
        nationalityRule() {
            const rules = [];
            if (this.field.nationality.is_required) {
                const rule = (v) => !!v || "Nationality is required";
                rules.push(rule);
            }
            return rules;
        },
        idTypeRule() {
            const rules = [];
            if (this.field.idProof.is_required) {
                const rule = (v) => !!v || "Please select ID Proof Type";
                rules.push(rule);
            }
            return rules;
        },
        dobRule() {
            const rules = [];
            if (this.field.dob.is_required) {
                const rule = (v) => !!v || "Date of Birth is required";
                rules.push(rule);
            }
            return rules;
        },

    },
    methods: {
        setFieldConfigurations() {
            let data = [];
            if (this.venue_service_id) {
                data = this.$store.getters.getShopBookingFields({vs_id: this.venue_service_id,type:this.bookingType});
            }else if (this.bookingType) {
                data = this.$store.getters.getShopBookingFields({type:this.bookingType});
            }
            if(data && data.length > 0) {
                data.forEach((field) => {
                    if (field.slug == this.field.mobile.slug) {
                        this.field.mobile.is_visible = field.is_visible == 1 ? true : false;
                        this.field.mobile.is_required = field.is_required == 1 ? true : false;
                    } else if (field.slug == this.field.name.slug) {
                        this.field.name.is_visible = field.is_visible == 1 ? true : false;
                        this.field.name.is_required = field.is_required == 1 ? true : false;
                    } else if (field.slug == this.field.email.slug) {
                        this.field.email.is_visible = field.is_visible == 1 ? true : false;
                        this.field.email.is_required = field.is_required == 1 ? true : false;
                    } else if (field.slug == this.field.gender.slug) {
                        this.field.gender.is_visible = field.is_visible == 1 ? true : false;
                        this.field.gender.is_required = field.is_required == 1 ? true : false;
                    } else if (field.slug == this.field.dob.slug) {
                        this.field.dob.is_visible = field.is_visible == 1 ? true : false;
                        this.field.dob.is_required = field.is_required == 1 ? true : false;
                    } else if (field.slug == this.field.tag.slug) {
                        this.field.tag.is_visible = field.is_visible == 1 ? true : false;
                        this.field.tag.is_required = field.is_required == 1 ? true : false;
                    } else if (field.slug == this.field.nationality.slug) {
                        this.field.nationality.is_visible =
                            field.is_visible == 1 ? true : false;
                        this.field.nationality.is_required =
                            field.is_required == 1 ? true : false;
                    } else if (field.slug == this.field.idProof.slug) {
                        this.field.idProof.is_visible = field.is_visible == 1 ? true : false;
                        this.field.idProof.is_required =
                            field.is_required == 1 ? true : false;
                    } else if (field.slug == this.field.image.slug) {
                        this.field.image.is_visible = field.is_visible == 1 ? true : false;
                        this.field.image.is_required = field.is_required == 1 ? true : false;
                    } else if (field.slug == this.field.terms_conditions.slug) {
                        this.field.terms_conditions.is_visible =
                            field.is_visible == 1 ? true : false;
                        this.field.terms_conditions.is_required =
                            field.is_required == 1 ? true : false;
                    }
                });
            }
             this.$forceUpdate();
        },
        setDocumentFields() {
            this.documentFields = [];
            if (this.bookingType) {
                this.documentFields = JSON.parse(JSON.stringify(this.$store.getters.getShopFieldDocuments(this.bookingType).filter((x) => x.is_visible)));
            }
        },
        idProofRule(bookingForm) {
            const rules = [];
            if (
                this.field.idProof.is_required &&
                bookingForm &&
                !bookingForm.id_proof_path
            ) {
                const rule = (v) => !!v || "ID Attachment is required";
                rules.push(rule);
            }
            return rules;
        },
        imageRule(bookingForm) {
            const rules = [];
            if (this.field.image.is_required && !bookingForm.image_path) {
                const rule = (v) => !!v || "Image is required";
                rules.push(rule);
            }
            return rules;
        },

    },
};
export default shopBookingFields;
